import logo from "./logo.webp"

const clientData = {
    client_entity: 9,
    attorney_firm: 'injurylawofsandiego.com',
    attorney_name: 'Ron Laba',
    attorney_number: '760-940-6231 / 760 622 9991',
    attorney_email: 'ronlaba@injurylawofsandiego.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_ron_b._laba+(720p).mp4',
    mainColor: '#1e3352',
    secondaryColor: '#f49231',
    siteLink: 'http://www.injurylawofsandiego.com',
    logo
}

export default clientData